@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  width: 400px;
  height: 200px;
  object-fit: cover;
}

.desktopIcons {
  @apply w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-light-component dark:bg-dark-component;
}

.mobileIcons {
  @apply flex justify-between items-center  bg-light-component dark:bg-dark-component;
}

.myHeader {
  @apply mb-24 md:mb-32 lg:mb-52 items-center w-full h-screen;
}

.myContainer {
  @apply my-24 md:my-32 lg:my-52 items-center w-full h-screen;
}

.headerText {
  @apply text-light-primary dark:text-dark-primary border-b-2 border-light-primary dark:border-dark-primary font-bold text-2xl md:text-4xl inline;
}

.primaryBtn {
  @apply text-sm py-2 px-4 rounded inline-flex items-center text-dark-text bg-light-text dark:bg-dark-primary focus:outline-none hover:bg-tahiti-300 dark:hover:bg-dark-secondary;
}

.secondaryBtn {
  @apply text-sm py-2 px-4 rounded inline-flex items-center text-dark-text bg-light-text dark:bg-dark-primary focus:outline-none hover:bg-tahiti-300 dark:hover:bg-dark-secondary;
}

.border {
  @apply border-2 border-tahiti-400 shadow-md rounded-lg border-opacity-50;
}

.primaryText {
  @apply sm:text-4xl text-3xl mb-4 font-medium text-primary;
}

.description {
  @apply mb-8 leading-relaxed text-light-text dark:text-dark-text;
}

.content-div {
  @apply bg-no-repeat bg-cover bg-center;
}

.project {
  @apply shadow-lg shadow-secondary container rounded-md flex justify-center items-center mx-auto content-div;
}

@layer base {
  body {
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}
